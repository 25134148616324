import React from 'react';
import styles from './DialogBody.module.scss';

export interface DialogBodyProps {
  children: React.ReactNode;
  imageSrc: string;
  imageAlt?: string;
}

export function DialogBody({ children, imageSrc, imageAlt }: DialogBodyProps) {
  return (
    <div className={styles.container}>
      <div>{children}</div>
      <div className={styles.imageContainer}>
        <img src={imageSrc} alt={imageAlt} />
      </div>
    </div>
  );
}
